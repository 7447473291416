
import {
    defineComponent, PropType,
    ref
} from 'vue';
import { orderType } from '@/data';
import activity from './activity.vue';
import Card from './card.vue';
import { initData } from './manage-info';
import CardDialog from './card-dialog.vue';

export default defineComponent({
    components: {
        activity,
        Card,
        CardDialog
    },
    props: {
        orderDetail: {
            type: Object as PropType<orderType.OrderInfo>,
            required: true
        }
    },
    setup(props) {
        const {
            orderData,
            projectUUIDSet
        } = initData(props.orderDetail);

        const isShowCardDialog = ref(false);
        const cardDialogProjectUUID = ref('');
        const cardDialogTitle = ref('');
        const showCardDialog = (item: string) => {
            cardDialogProjectUUID.value = item;
            cardDialogTitle.value = `${WordList.Details}-${orderData.value[item].projectName}`;
            isShowCardDialog.value = true;
        };

        const showFeeType = ['3', '5', '6', '7'];

        return {
            orderData,
            projectUUIDSet,
            showCardDialog,
            isShowCardDialog,
            cardDialogProjectUUID,
            cardDialogTitle,
            showFeeType
        };
    }
});
