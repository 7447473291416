import { ref } from 'vue';
import { orderType } from '@/data';
import { OrderDataList } from '@/components/view/common/payment/all.type';
import { discountUnitEx100, discountUnitTo100 } from '@/methods/count';

const mainPayType = ['1', '2', '5', '8'];
const subPayType = ['3'];
const pmAppPayType = ['9', '10'];

const initData = (
    orderDetail: orderType.OrderInfo
) => {
    // 处理后的数据
    const orderData = ref<OrderDataList>({});
    // 存储社区UUID
    const projectUUIDSet = new Set();
    // 处理数据
    (orderDetail as any).Children.forEach((detail: any) => {
        projectUUIDSet.add(detail.ProjectUUID);
        const uuid = detail.ProjectUUID;
        if (!orderData.value[uuid]) {
            orderData.value[uuid] = {
                main: [],
                sub: [],
                pmApp: [],
                price: 0,
                projectName: detail.ProjectName,
                items: 0
            };
        }

        if (mainPayType.includes(detail.Type)) {
            orderData.value[uuid].main.push(detail);
        } else if (subPayType.includes(detail.Type)) {
            orderData.value[uuid].sub.push(detail);
        } else if (pmAppPayType.includes(detail.Type)) {
            orderData.value[uuid].pmApp.push(detail);
        }
        orderData.value[uuid].price += detail.Price;
        orderData.value[uuid].items += 1;
    });

    Object.keys(orderData.value).forEach((uuid) => {
        orderData.value[uuid].price = discountUnitEx100(discountUnitTo100(orderData.value[uuid].price));
        if (orderDetail.TypeEnum === '2' || orderDetail.TypeEnum === '4') {
            orderData.value[uuid].main.forEach((item) => {
                item.Price = discountUnitEx100(discountUnitTo100(item.Price * Number(orderDetail.Months)));
            });
            orderData.value[uuid].sub.forEach((item) => {
                item.Price = discountUnitEx100(discountUnitTo100(item.Price * Number(orderDetail.Months)));
            });
            orderData.value[uuid].pmApp.forEach((item) => {
                item.Price = discountUnitEx100(discountUnitTo100(item.Price * Number(orderDetail.Months)));
            });
        }
    });

    return {
        orderData,
        projectUUIDSet
    };
};

export default null;
export {
    initData
};
